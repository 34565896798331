import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

import { PlayCircleFill } from '@styled-icons/bootstrap/PlayCircleFill'
import { Close } from '@styled-icons/material-rounded/Close'

export const VideoBannerContainer = styled.div`
  position: relative;
`

export const CollapsibleStyled = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: block;
  `}
`

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  z-index: 150;
  top: 0;

  background-color: rgba(0, 0, 0, 0.6);
`

export const ModalContent = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CloseIcon = styled(Close)`
  color: ${props => props.theme.colors.white};
  position: absolute;
  top: 0;
  right: 0;
  margin: 2.4rem;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.danger};
  }
`

export const PlayCircleIcon = styled(PlayCircleFill)`
  color: ${props => props.theme.colors.white};
  position: absolute;
  top: calc(50% - (${props => props.size}px / 2));
  left: calc(50% - (${props => props.size}px / 2));
  z-index: 5;
  cursor: pointer;
`

export const StyledTitle = styled.h1`
  color: ${props => props.theme.colors.secondary};
  font-size: 4.8rem;
  margin-top: 4.8rem;
  margin-bottom: 1.6rem;
  margin-left: 9.7rem;
  font-weight: normal;

  ${media.lessThan('medium')`
    margin-top: 3.2rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    font-size: 3.2rem;
  `}
`

export const StyledContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  color: ${props => props.theme.colors.brownishGrey};
  font-size: 1.6rem;
  padding-left: 9.7rem;

  overflow: hidden;

  p {
    margin-top: 0;
    margin-bottom: 2.4rem;
    line-height: 2.4rem;
  }

  a {
    color: ${props => props.theme.colors.brownishGrey};
  }

  ${media.lessThan('medium')`
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    height: ${props => ['100%', '40rem'][Number(props.collapsed)]};
  `}
`

export const CollapsibleContainer = styled.div`
  position: relative;
`

export const CollapsibleButton = styled.span`
  font-size: 16px;
  line-height: 24px;

  color: ${props => props.theme.colors.primary};
  text-decoration-line: underline;

  margin-left: 2.4rem;
  margin-right: 2.4rem;
`

export const CollapsibleShadow = styled.div`
  width: 100%;
  height 15rem;
  position: absolute;
  bottom: 0;
  background-color: white;

  ${props => [`display: none`, `mask-image: -webkit-gradient(linear, left top,
    left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,1)));`][Number(props.collapsed)]};
 `

export const AboutPageContainer = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 14.5rem;
  justify-content: space-between;

  ${media.lessThan('large')`
    gap: 7.5rem;
  `}

  ${media.lessThan('medium')`
    gap: 0;
    flex-direction: column;
  `}
`

export const AsideImage = styled(Img)`
  width: 100%;
  object-fit: cover;

  height: 75rem;
  ${media.lessThan('medium')`
    margin-top: 2.4rem;
  `}|
`

export const BannerImage = styled(Img)`
  width: 100%;
  object-fit: cover;
  height: 38.4rem;

  filter: ${props => ['brightness(100%)', 'brightness(50%)'][Number(props.isVideo)]};
`
