import React, { useState } from 'react'

import {
  BannerImage,
  PlayCircleIcon,
  VideoBannerContainer,
  ModalContainer,
  ModalContent,
  CloseIcon,
} from './styled'

import getYoutubeId from '../../utils/getYoutubeId'

const VideoBanner = ({ type, image, video, active }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const isVideo = type === 'Video'
  if (!active) {
    return null
  }

  const scrolling = (overflow, height, top, position) => {
    document.body.style.overflow = overflow
    document.body.style.height = height
    document.body.style.top = top
    document.body.style.position = position
  }

  const toggleModal = () => {
    if (modalOpen) {
      scrolling('auto', '', '', '')
      return setModalOpen(false)
    }

    scrolling('hidden', '100vh', `-${window.scrollY}px`, 'fixed')
    return setModalOpen(true)
  }

  const Modal = ({ embedLink, modalOpen }) => {
    if (!modalOpen) {
      return null
    }

    return (
      <ModalContainer data-testid="modal">
        <ModalContent>
          <iframe
            width="90%"
            height="70%"
            src={embedLink}
            title="Alta"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          />

          <CloseIcon size={40} onClick={toggleModal} data-testid="close-button" />
        </ModalContent>
      </ModalContainer>
    )
  }

  return (
    <VideoBannerContainer>
      {isVideo && <PlayCircleIcon size={40} onClick={toggleModal} data-testid="play-button" />}

      <BannerImage
        isVideo={isVideo}
        fluid={image.localFile.childImageSharp.fluid}
        alt={image.alt_text}
      />

      <Modal
        modalOpen={modalOpen}
        embedLink={`https://www.youtube.com/embed/${getYoutubeId(video)}?autoplay=1`}
      />
    </VideoBannerContainer>
  )
}

export default VideoBanner
