import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/seo'

import Breadcrumb from '../components/Breadcrumb'

import { Title, Content, VideoBanner } from '../components/About'

import { AsideImage, AboutPageContainer } from '../components/About/styled'

const About = ({ data, pageContext, location }) => {
  const aboutGatsbyPage = data.wordpressWpGatsbyPage.acf

  const {
    about_page_banner_image,
    about_page_video_link,
    title,
    description,
    canonical,
    about_page_title,
    about_page_content,
    about_page_image_aside,
    about_banner_active,
    about_banner_type,
  } = aboutGatsbyPage

  const settings = data.settings

  return (
    <Layout pageContext={pageContext} location={location} hasBreadcrumb={false}>
      <SEO
        title={`${title} | ${settings.brand_name}`}
        description={description}
        canonical={canonical}
      />

      <VideoBanner
        type={about_banner_type}
        active={about_banner_active}
        image={about_page_banner_image}
        video={about_page_video_link}
      />

      <Breadcrumb pageContext={pageContext} location={location} />

      <Title>{about_page_title}</Title>

      <AboutPageContainer>
        <Content html={about_page_content} />
        <AsideImage
          fluid={about_page_image_aside.localFile.childImageSharp.fluid}
          alt={about_page_image_aside.alt_text}
        />
      </AboutPageContainer>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    wordpressWpGatsbyPage(slug: { eq: "sobre-o-alta-excelencia-diagnostica" }) {
      acf {
        about_banner_active
        about_page_content
        about_page_title
        about_page_video_link
        about_banner_type

        about_page_banner_image {
          alt_text
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }

        about_page_image_aside {
          alt_text
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }

        title
        description
        canonical
      }
    }
    settings: wordpressAltaThemeSettings {
      brand_name
    }
  }
`
