import React, { useState } from 'react'

import {
  CollapsibleButton,
  StyledContent,
  CollapsibleContainer,
  CollapsibleShadow,
  CollapsibleStyled,
} from './styled'

const Content = ({ html }) => {
  const willCollapse = html.length > 700

  const [collapsed, setCollapsed] = useState(willCollapse)

  const buttonText = ['Ler Menos', 'Ler Mais'][Number(collapsed)]

  const handleClick = () => setCollapsed(prevCollapsed => !prevCollapsed)

  return (
    <>
      <StyledContent collapsed={collapsed} dangerouslySetInnerHTML={{ __html: html }} />

      {willCollapse && (
        <CollapsibleStyled>
          <CollapsibleContainer>
            <CollapsibleShadow collapsed={collapsed} />
          </CollapsibleContainer>

          <CollapsibleButton onClick={handleClick} data-testid="colapsable-button">
            {buttonText}
          </CollapsibleButton>
        </CollapsibleStyled>
      )}
    </>
  )
}
export default Content
